// Import site utilities.
@import '../../global/utils/init';

.slider_wrapper {
	justify-content: center;
	overflow: hidden;
	width: 100%;
	.block--inner-wrapper {
		max-width: 100vw;
		margin: 0px;
		padding: 0px;
		.flexslider  {
			border: 0;
			border-radius: 0;
			margin: 0;
			img {
				height: 720px;
				object-fit: cover;
				@include lg {
					height: 400px;
				}
			}
			.views-field-nothing{
				background: rgba(0, 0, 0, 0.4);
				bottom: 0;
				color: #FFFFFF;
				font-size: 16px;
				height: 100%;
				left: 0;
				position: absolute;
				width: 100%;
				.field-content {
					.slider-content {
						.slider-title {
							font-size: 38px;
							font-weight: 600;
							position: absolute;
			    			left: 9%;
							top: 38%;
							@include lg {
								position: unset;
							}
						}
						.slider-body {
							font-size: 20px;
							max-width: 480px;
							position: absolute;
							left: 9%;
							top: 50%;
							@include lg {
								position: unset;
							}
						}
						@include lg {
							display: flex;
							flex-direction:column;
							height: 100%;
						    justify-content: center;
						    padding: 0 15px;
						}
					}
				}
			}
			.flex-next {
				display: none;
			}
			.flex-prev {
				display: none;
			}
			.flex-control-nav {
				bottom: 5%;
				z-index: 4;
				li {
					margin: 0 10px;
					a {
						background-color: white;
						opacity: .5;
						padding: 6px;
					}
				}
			}
		}
	}
}



.front {
	.l-top {
		.block{
			&.slider_wrapper {
				padding: 0;
			}
		}
	}
}
